import React from 'react'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'

const CategoryPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <section className="blog-post">
      <div className="blog-post-wrapper">
        <Helmet title={`Categories | ${title}`} />
        <div className="max-w-page mx-auto text-center pb-20">
          <h3 className="text-60 mb-4 font-bold">Categories</h3>
        </div>
        <ul className="text-center text-20">
          {group.map(category => (
            <li key={category.fieldValue} className="mb-4">
              <Link to={`/category/${kebabCase(category.fieldValue)}/`}>
                {category.fieldValue} ({category.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  </Layout>
)

export default CategoryPage

export const categoryPageQuery = graphql`
  query CategoryQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`
